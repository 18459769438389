import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';


export default function CoinTransferSidebar() {
    const { formatAddress, toastSuccess, toastError,formatDateTime } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "admin",
                    submethod: "getalltransfercoin",
                    key: process.env.REACT_APP_KEY,
                    search: search,
                    fromdate: fromdate,
                    todate: todate,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    console.log("user data", res.data.data);
                    setLoading(false);
                    if (res.data.error) {
                        setdata(res.data.data);
                        return "";
                    }
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.total / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        if (fromdate && todate) {
            setCurrentPage(1);
        }
        getData();
    }, [pages, pageSize, currentPage, search]);
    return (
        <>
            <>
                <main className="page-wrapper compact-wrapper" id="pageWrapper">
                    <Header />
                    <div className="page-body-wrapper">
                        <Sidebar />
                        <div className="page-body">
                            <div className="container-fluid">
                                <div className="row page-title">
                                    <div className="col-sm-6">
                                        <h3>Coin Transfer Details</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="form-head d-flex align-items-center">
                            <div className="input-group search-area d-inline-flex m-3"
                                    style={{ width: "25%" }} // Reduced to 70% of its original size
                                    >
                            
                                <input
                                    type="text"
                                    className="form-control form-control-rev pe-0"
                                    placeholder="Search here"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="input-group-text input-group-text-rev "
                                        onClick={() => setSearch("")}
                                    >
                                        {search ? (
                                            <img
                                                src="./assets/images/close.png"
                                                alt=""
                                                style={{ height: "30px" }}
                                            />
                                        ) : (
                                            <i className="flaticon-381-search-2" />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>   
                            {/* Container-fluid starts*/}
                            <div className="container-fluid">
                                <div class="row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-header">
                                            </div>
                                            <div className="table-responsive signal-table p-3">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>User Name</th>
                                                            <th>To</th>
                                                            <th>Coin Amount</th>                                                           
                                                            <th>Datetime</th>
                                                            {/* <th>Status</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data && data?.length
                                                            ? data.map((e, i) => {
                                                                let ind = currentPage * pageSize - pageSize + (i + 1);
                                                                return (
                                                                    <tr>
                                                                        <td >
                                                                            {ind}
                                                                        </td>
                                                                        <td >
                                                                        {e.username ? e.username : "-"}
                                                                        </td>
                                                                        <td >
                                                                        {e.tousername ? e.tousername : "-"}
                                                                        </td>
                                                                        <td >
                                                                       $ {e.coinAmount ? e.coinAmount : "-"}
                                                                        </td>
                                                                        <td >
                                                                        {formatDateTime(e.createdAt)}
                                                                        </td>                                                                     
                                                                    </tr>
                                                                );
                                                            })
                                                            : ""}
                                                    </tbody>
                                                </table>
                                                {loading ? (
                                                    <div className="text-center">
                                                        <p>Loading...</p>
                                                    </div>
                                                ) : !data || !data.length ? (
                                                    <div className="text-center">
                                                        <p>No data found.</p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className='d-flex justify-content-center py-4'>
                                                <Pagination
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    pageSize={pageSize}
                                                    setPageSize={setPageSize}
                                                    pages={pages}
                                                />
                                                {/* <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </nav> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Scroll - vertical dynamic Ends*/}
                            </div>
                            {/* Container-fluid starts*/}


                        </div>

                        <Footer />
                    </div>

                </main>

            </>

        </>
    )
}
