import React, { useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function CoinTransfer() {
    const [username, setUsername] = useState('');
    const [tousername, setTousername] = useState('');
    const [coinAmount, setCoinAmount] = useState('');
    const [userBalance, setUserBalance] = useState(null); // State to store the user's balance
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [msg, setMsg] = useState('');

    // Fetch User Balance
    const getUserBalance = async (userId) => {
        if (!userId) return;

        try {
            setLoading(true);

            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "admin",
                submethod: "getalluserbalance",
                search: userId,
                key: process.env.REACT_APP_KEY,
            });

            setLoading(false);
            console.log(' response.data.balance', response.data.data)
            if (response.data && response.data.resCode === 200) {
                const balance = response.data || 0; // Adjust field based on actual response
                setUserBalance(balance);
            } else {
                toast.error("Failed to fetch user balance", { className: 'custom-toast' });
                setUserBalance(null);
            }
        } catch (err) {
            console.error("Error fetching user balance:", err);
            toast.error("Network error. Please try again later.", { className: 'custom-toast' });
            setLoading(false);
            setUserBalance(null);
        }
    };

    // Call getUserBalance when the username changes
    useEffect(() => {
        if (username) {
            getUserBalance(username);
        } else {
            setUserBalance(null);
        }
    }, [username]);

    const insertData = async () => {
        if (!username || !tousername || !coinAmount) {
            toast.error("All fields are required!", { className: 'custom-toast' });
            return;
        }

        if (parseFloat(coinAmount) > parseFloat(userBalance)) {
            toast.error("Insufficient balance!", { className: 'custom-toast' });
            return;
        }

        try {
            setLoading(true);

            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "transfercoin",
                submethod: "insert",
                username: parseInt(username),
                tousername: parseInt(tousername),
                coinAmount: parseFloat(coinAmount),
                key: process.env.REACT_APP_KEY,
            });

            setLoading(false);

            const { resCode, error } = response.data;

            if (resCode === 200) {
                toast.success("Coin transfer successful!", { className: 'custom-toast' });
                setMsg("Coin transfer successful!");
                setShowMsg(true);
                setUsername('');
                setTousername('');
                setCoinAmount('');
                setUserBalance(null);
            } else {
                toast.error(`Error: ${error || "An unexpected error occurred"}`, { className: 'custom-toast' });
                setErrorMessage(error || "An unexpected error occurred");
                setShowError(true);
            }
        } catch (err) {
            console.error("Error during coin transfer:", err);
            toast.error("Network error. Please try again later.", { className: 'custom-toast' });
            setErrorMessage("Network error. Please try again later.");
            setShowError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        let timer;
        if (showError || showMsg) {
            timer = setTimeout(() => {
                setShowError(false);
                setShowMsg(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [showError, showMsg]);

    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container ecommerce-dashboard">
                            <div className="row justify-content-center">
                                <div className="col-6 pt-5">
                                    <div className="card login-dark p-5">
                                        <div>
                                            <div className="login-main">
                                                <form className="theme-form">
                                                    <h2 className="text-center">Coin Transfer</h2>

                                                    {/* Username Input */}
                                                    <div className="form-group">
                                                        <label className="col-form-label">From User ID</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            required
                                                            placeholder="Enter your User ID"
                                                            value={username}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                setUsername(value);
                                                                if (value.length === 0) {
                                                                    setUsernameError('User not found');
                                                                } else {
                                                                    setUsernameError('');
                                                                }
                                                            }}
                                                        />
                                                        {usernameError && <p className="text-danger mt-2">{usernameError}</p>}
                                                    </div>

                                                    {/* Display User Balance */}
                                                    {userBalance !== null && userBalance.data && userBalance.data[0] ? (
                                                        <div className="form-group">
                                                            <label className="col-form-label">Your Balance</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={`$${userBalance.data[0].totalclaim}`}
                                                                disabled
                                                            />
                                                        </div>
                                                    ) : (
                                                        username && <p className="text-warning">User balance not available.</p>
                                                    )}



                                                    {/* To User Input */}
                                                    <div className="form-group">
                                                        <label className="col-form-label">To User ID</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            required
                                                            placeholder="Enter recipient User ID"
                                                            value={tousername}
                                                            onChange={(e) => setTousername(e.target.value)}
                                                        />
                                                    </div>

                                                    {/* Amount Input */}
                                                    <div className="form-group">
                                                        <label className="col-form-label">Amount</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            required
                                                            placeholder="Enter amount to transfer"
                                                            value={coinAmount}
                                                            onChange={(e) => setCoinAmount(e.target.value)}
                                                        />
                                                    </div>

                                                    {/* Submit Button */}
                                                    <div className="form-group mb-0 checkbox-checked mb-5">
                                                        <div className="text-end mt-3">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-block w-100 text-white"
                                                                onClick={insertData}
                                                                disabled={loading}
                                                            >
                                                                {loading ? "Processing..." : "Submit"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>

                                                {/* Success/Error Messages */}
                                                {showMsg && <p className="text-success text-center">{msg}</p>}
                                                {showError && <p className="text-danger text-center">{errorMessage}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}
