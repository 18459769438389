import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';


export default function EnergyList(username) {
    const { formatAddress, toastSuccess, toastError,formatDateTime } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "boosterpkg",
                    submethod: "gettap",
                    key: process.env.REACT_APP_KEY,
                    username: username,
                    search,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    setLoading(false);
                    if (res.data.error) {
                        return "";
                    }
                    console.log('res.data.data', res.data.data)
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (username) {
            getData();
        }
    }, [username, pages, pageSize, currentPage]);

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        getData();
    }, [search]);
    return (
        <>
            <div class="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3>Energy List</h3>
                        </div>
                        <div className="table-responsive signal-table p-3">
                            <table className="table table-hover">
                            <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>User Name</th>
                                                            <th>Rank</th>
                                                            <th>Reward</th>                                                           
                                                            <th>Datetime</th>
                                                            {/* <th>Status</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data && data?.length
                                                            ? data.map((e, i) => {
                                                                let ind = currentPage * pageSize - pageSize + (i + 1);
                                                                return (
                                                                    <tr>
                                                                        <td >
                                                                            {ind}
                                                                        </td>
                                                                        <td >
                                                                        {e.username ? e.username : "-"}
                                                                        </td>
                                                                        <td >
                                                                        {e.number ? e.number : "-"}
                                                                        </td>
                                                                        <td >
                                                                       $ {e.reward ? e.reward : "-"}
                                                                        </td>
                                                                        <td >
                                                                        {formatDateTime(e.date)}
                                                                        </td>                                                                     
                                                                    </tr>
                                                                );
                                                            })
                                                            : ""}
                                                    </tbody>
                            </table>
                            {loading ? (
                                <div className="text-center">
                                    <p>Loading...</p>
                                </div>
                            ) : !data || !data.length ? (
                                <div className="text-center">
                                    <p>No data found.</p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className='d-flex justify-content-center py-4'>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                            {/* <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </nav> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
