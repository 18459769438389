import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
    return (
        <>
            <div className="overlay" />
            <aside className="page-sidebar" data-sidebar-layout="stroke-svg">
                <div className="left-arrow" id="left-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-left" />
                    </svg>
                </div>
                <div id="sidebar-menu">
                    <ul className="sidebar-menu" id="simple-bar">
                        <li className="pin-title sidebar-list p-0">
                            <h5 className="sidebar-main-title">Pinned</h5>
                        </li>
                        <li className="line pin-line" />
                        <li className="sidebar-main-title">General</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg>
                            <Link to="/dashboard" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Dashboard</span>
                            </Link>
                        </li>                      
                        <li className="line"> </li>
                        <li className="sidebar-main-title">Users Report</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/users" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Users</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Booster Report</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/tapping-income" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span> Tapping Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/level-income" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span> Sponsor Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/loyaltyreward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span> Loyalty Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/cointransfersidebar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Coin Transfer Details</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/iphoneachievers" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Iphone Achievers</span>
                            </Link>
                        </li>
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/cointransfer" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Coin Transfer</span>
                            </Link>
                        </li> */}
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pendingwithdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Withdrawal</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="right-arrow" id="right-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-right" />
                    </svg>
                </div>
            </aside>
        </>
    )
}
