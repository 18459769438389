import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Sidebar from '../Comman/Sidebar'
import Staking from './Sponsor'
import { AdminContext } from '../../AdminContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Team from './TappingDetails'
import StakingIncome from './StakingIncome'
import DirectReward from './DirectReward'
import CommunityReward from './PoolReward'
import RoyaltyClubReward from './RoyaltyClubReward'
import RankReward from './RankReward'
import Withdrawal from './Withdrawal'
import Ledger from './Ledger'
import PoolReward from './PoolReward'
import Sponsor from './Sponsor'
import EnergyList from './EnergyList'

export default function MemberReport() {
    const { username } = useParams();
    const { formatAddress, dbuser, formatDateTime, dbbalance } = useContext(AdminContext);
    const [data, setdata] = useState([]);
    const [totReward, setTotReward] = useState(0);
    const [balance, setbalance] = useState(0);
    const [level, setlevel] = useState(1);

    const getData = async () => {
        console.log("username", username);
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "getbyid",
                key: process.env.REACT_APP_KEY,
                username: username,
            })
            .then((res) => {
                if (res.data.error) {
                    return "";
                }
                console.log("member report", res.data.data);
                setdata(res.data.data);
            });
    };
    useEffect(() => {
        if (username) {
            getData();
        }
    }, [username]);
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page sidebar start*/}
                    <Sidebar />
                    {/* Page sidebar end*/}
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>MemberReport</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts*/}
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {/* Order Delivery*/}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">UserId</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{username || ""}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Earn Per Tap</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ? data.earn_per_tap : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Daily Earn</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ? data.dailyEarn : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ? data.totalpendingReward : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Booster</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ? data.lastbooster : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">My Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ? data.myreward : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ? data.myTeam : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Sponsor Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ? data.sponserReward : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Pending Sponser</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ? data.t_staking : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Loyalty Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ? data.salaryReward : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Sponsor username={username} />
                            <Team username={username} />
                            <StakingIncome username={username} />
                            <DirectReward username={username} />
                            <PoolReward username={username} />
                            <EnergyList username={username} />
                            {/* <RoyaltyClubReward address={address} />
                            <RankReward address={address} /> */}
                            <Withdrawal username={username} />
                            {/* <Ledger address={address} /> */}
                        </div>
                        {/* Container-fluid ends*/}
                    </div>
                    <Footer />
                </div>

            </main>


        </>
    )
}
