import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Login() {
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [error, seterror] = useState("");
    const navigate = useNavigate();

    // const getCheck = async () => {
    //     await axios
    //         .post(process.env.REACT_APP_BACKEND_LINK, {
    //             method: "adminLogin",
    //             submethod: "check",
    //             username: username,
    //             password: password,
    //             key: process.env.REACT_APP_KEY,
    //         })
    //         .then((res) => {
    //             if (res.data.error) {
    //                 return "";
    //             }
    //             console.log("res.data", res.data.status)
    //             if (res.data.status === "1") {
    //                 navigate("/authentication", {
    //                     state: { username: res.data.username, _id: res.data._id },
    //                 });
    //             } else {
    //                 seterror("Invalid credential");
    //             }
    //         });
    // };
    const getCheck = async () => {
        // Check if fields are filled
        if (!username || !password) {
            seterror("Username and password are required.");
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "adminlogin",
                submethod: "check",
                username: username,
                password: password,
                key: process.env.REACT_APP_KEY,
            });
            console.log('response', response)
            
            // Check the response
            if (response.data.error) {
                seterror("Login failed, please try again.");
                return;
            }
            if (response.data.status === "1") {
                navigate("/authentication", {
                    state: { username: response.data.username, _id: response.data._id },
                });
            } else {
                seterror("Invalid credentials.");
            }
        } catch (err) {
            seterror("An error occurred. Please try again.");
        }
    };
    useEffect(() => {
        localStorage.clear()
    })


    return (
        <>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="login-card login-dark">
                            <div>
                                {/* <div><img className="img-fluid for-light" src="../assets/images/logo/logo.png" alt="looginpage" /><img className="img-fluid for-dark m-auto" src="../assets/images/logo/dark-logo.png" alt="logo" /></div> */}
                                <div className="login-main">
                                    <form className="theme-form">
                                        <h2 className="text-center">Sign in to account</h2>
                                        <p className="text-center">Enter your username &amp; password to login</p>
                                        <div className="form-group">
                                            <label className="col-form-label">Username</label>
                                            <input className="form-control" type="text" required placeholder="Username" onChange={(e) => setusername(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Password</label>
                                            <div className="form-input position-relative">
                                                <input className="form-control" type="password" placeholder="password" onChange={(e) => setpassword(e.target.value)} />
                                                <div className="show-hide"><span className="show"></span></div>
                                            </div>
                                        </div>
                                        {error ? <div class="alert alert-danger solid alert-square ">{error}</div> : ""}
                                        <div className="form-group mb-0 checkbox-checked mb-5">
                                            <div className="text-end mt-3"><a className="btn btn-primary btn-block w-100 text-white" onClick={() => getCheck()}>Log in </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
