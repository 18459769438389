import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import Web3 from "web3";
import axios from "axios";
import { AdminContext } from '../../AdminContext';
import { ethers } from "ethers";
import { formatEther } from 'ethers/lib/utils';
import { toast } from 'react-toastify'

const web3 = new Web3(
    new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
);

export default function UserDeposit() {
    const { rdpkg, fdpkg, tokenrate, formatDateTime, settings, getsmcs, } = useContext(AdminContext);
    const [transactionHash, setTransactionHash] = useState("");
    const [receipt, setReceipt] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [msg, setMsg] = useState("");
    const [showError, setShowError] = useState(false);
    const [showMsg, setShowMsg] = useState(false);

    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);

    const [hash, setHash] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [username, setUsername] = useState(' ');
    const [dropdownVisible, setDropdownVisible] = useState(false);


    const fetchDropdownData = async (username) => {
        if (!username) return;
        try {
            setLoading(true);
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: 'package',
                submethod: 'getpkgdata',
                key: process.env.REACT_APP_KEY,
                username: username,
            });

            console.log("API Response:", response);

            setLoading(false);

            if (response.data && response.data.data) {
                setDropdownData(response.data.data);
                setDropdownVisible(true);
            } else {
                setDropdownData([]);
                setDropdownVisible(false);
            }
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
            setLoading(false);
            setDropdownData([]);
            setDropdownVisible(false);
        }
    };

    // Handle User ID change
    const handleUserIdChange = (e) => {
        const id = e.target.value;
        setUsername(id);
        console.log('Username entered:', id);
        fetchDropdownData(id); // Fetch data for entered User ID
    };

    // Handle form submission
    const handleInsertData = () => {
        console.log('Submitted Data:', { username, selectedOption });
        // Call your API to handle form submission
    };

    // Handle Submit Button Click
    const handleSubmit = async () => {
        // You can perform any logic here before submitting
        if (!username || !selectedOption) {
            alert("Please select both User ID and an option.");
            return;
        }
        // Call the insert data function or API
        handleInsertData();
    };

    useEffect(() => {
        console.log("Dropdown Data:", dropdownData); // Log the dropdownData whenever it changes
    }, [dropdownData]);



    // Function to generate a random hash
    function generateRandomHash(length = 64) {
        // Create a random value using the crypto module
        const randomBuffer = crypto.getRandomValues(new Uint8Array(length));

        // Convert the buffer to a hexadecimal string (hash-like format)
        return Array.from(randomBuffer)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
    }
    const [toncoinPrice, setToncoinPrice] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchToncoinPrice = async () => {
            try {
                const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
                    params: {
                        ids: 'the-open-network',
                        vs_currencies: 'usd',
                    },
                });

                const calculatedrate = Number(selectedOption) / parseFloat(response.data['the-open-network'].usd);
                console.log("const calculatedrate = Number(price) / parseFloat(response.data['the-open-network'].usd)", calculatedrate.toString());
                setToncoinPrice(calculatedrate);
            } catch (err) {
                console.error('Error fetching Toncoin price:', err);
                setError('Failed to fetch Toncoin price');
            }
        };

        console.log("calculatedrate.toFixed(4))", toncoinPrice);


        fetchToncoinPrice();
    }, []);

    const insertData = async () => {
        console.log("insertData", username, selectedOption, toncoinPrice);

        try {
            const randomHash = generateRandomHash();

            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "transaction",
                submethod: "insert",
                hash: `admin-${randomHash}`,
                username: username,
                packageamt: selectedOption,
                coin_amount: 10,
                key: process.env.REACT_APP_KEY,
            });

            console.log("data", response.data);

            const { resCode, error } = response.data;

            // Check if the response was successful (status 200)
            if (resCode === 200) {
                // Show success message with toast
                toast.success("Data inserted successfully!", { className: 'custom-toast' });

                // Additional UI updates for success
                setMsg("Data inserted successfully");
                setShowMsg(true);
                setTransactionHash("");
                setReceipt(null);
            } else {
                // Show error message with toast if resCode is not 200
                toast.error(`Error: ${response.data.error || "An unexpected error occurred"}`, { className: 'custom-toast' });

                // Set error state for UI update
                setErrorMessage(error || "An unexpected error occurred");
                setShowError(true);
            }
        } catch (err) {
            console.error("Error inserting data:", err);

            // Show network error message if API call fails
            toast.error("Network error. Please try again later.", { className: 'custom-toast' });

            // Set error state for UI update
            setErrorMessage("Network error. Please try again later.");
            setShowError(true);
        }
    };

    useEffect(() => {
        let timer;
        if (showError) {
            timer = setTimeout(() => setShowError(false), 2000);
        }
        if (showMsg) {
            timer = setTimeout(() => setShowMsg(false), 2000);
        }
        return () => clearTimeout(timer);
    }, [showError, showMsg]);


    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container ecommerce-dashboard">
                            <div className="row justify-content-center">
                                <div className="col-6 pt-5">
                                    <div className="card login-dark p-5">
                                        <div>
                                            <div className="login-main">
                                                <form className="theme-form">
                                                    <h2 className="text-center">User Deposit</h2>

                                                    {/* User ID Input */}
                                                    <div className="form-group">
                                                        <label className="col-form-label">User ID</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            required
                                                            placeholder="User ID"
                                                            value={username}
                                                            onChange={handleUserIdChange}
                                                        />
                                                    </div>

                                                    {/* Conditionally render the dropdown after User ID is entered */}
                                                    {dropdownVisible && username && (
                                                        <div className="form-group">
                                                            <label className="col-form-label">Select Packages</label>
                                                            <select
                                                                className="form-control"
                                                                value={selectedOption}
                                                                onChange={(e) => setSelectedOption(e.target.value)}
                                                            >
                                                                <option value="" disabled>
                                                                    {loading ? "Loading..." : "Select an Packages"}
                                                                </option>
                                                                {dropdownData.length > 0 &&
                                                                    dropdownData.map((option, index) => (
                                                                        <option key={index} value={option.price}>
                                                                            Booster {index + 1}{"  "} ${option.price}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    )}

                                                    {/* Submit Button */}
                                                    <div className="form-group mb-0 checkbox-checked mb-5">
                                                        <div className="text-end mt-3">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-block w-100 text-white"
                                                                onClick={() => insertData()}
                                                            // disabled={!username || !selectedOption}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}


