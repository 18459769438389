import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import axios from "axios";
import copy from "copy-to-clipboard";
import Web3 from "web3";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useSwitchNetwork,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AdminContext = createContext();

const web3 = new Web3(process.env.REACT_APP_RPC);

const projectId = process.env.REACT_APP_PROJECT_ID;
const mainnet = [
  {
    chainId: 56,
    name: "BSC Mainnet",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-dataseed1.binance.org/",
  },
];

const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "http://localhost:3000",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: mainnet[0].chainId,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...",
  }),
  chains: mainnet,
  projectId,
  enableAnalytics: true,
});

function Admincontext() {
  const [settings, setsettings] = useState([]);
  const [dashd, setdashd] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("g4fs34t3f4s5a") || "")
  const [pwtoken, setPWToken] = useState(localStorage.getItem("2faAuth") || "")
  const [pWithdrawal, setPWithdrawal] = useState(false);
  const [fromLocation, setfromLocation] = useState("");


  const [esigner, setSigner] = useState(null);
  const [stakcontract, setStakcontract] = useState(null);
  const [tokenrate, setTokenRate] = useState("");
  const [balance, setBalance] = useState(0);
  const { address, isConnected } = useWeb3ModalAccount();
  const [account, setAccount] = useState(address);
  const { walletProvider } = useWeb3ModalProvider();

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  const copyText = (data) => {
    copy(data);
  };
  const getSigner = async () => {

    const rpcUrl = process.env.REACT_APP_RPC; // Replace with your RPC URL
    const ethersProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const signer = await ethersProvider.getSigner();
    setSigner(signer)

  }
  const getSetting = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // console.log("res", res.data.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };

  const getsmcs = async () => {
    // console.log("setting",settings)
    if (!settings.token || esigner === null) return;

    console.log("settings.token,", settings.token);
    const rpcUrl = process.env.REACT_APP_RPC; // Replace with your RPC URL

    const ethersProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
    
    // let contract = new ethers.Contract(settings.token, JSON.parse(settings.token_abi), ethersProvider);
    // console.log("maincontractwrite 123132", settings);
    
    let maincontractwrite = new ethers.Contract(
      settings.node_contract,
      JSON.parse(settings.node_contract_abi),
      ethersProvider
    );
    setStakcontract(maincontractwrite)
    var tknrate = await maincontractwrite.getTokenPrice();
    // const price = await maincontractwrite.getTokenPrice().call();
    // console.log("Token Price:", tknrate);
    setTokenRate(tknrate);
    // setTokenRate(Number(ethers.utils.formatUnits(price, 18)).toFixed(2));
    // console.log("Tokenrate", Number(ethers.utils.formatUnits(tknrate, 18)).toFixed(2))
    // var balance = await contract.balanceOf(account);
    // console.log("Balance", Number(ethers.utils.formatUnits(balance, 18)).toFixed(2))
    // setBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

  };
  useEffect(() => {
    getsmcs();

    // }, [account, address,library, settings]);
  }, [isConnected, account, walletProvider, settings, esigner]);
  useEffect(() => {
    getSigner();
  }, [isConnected, account, walletProvider]);
  const getDashData = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // console.log("resdasdas", res.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdashd(res.data.data);
      });
  };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };

  useEffect(() => {
    getSetting();
    getDashData();
  }, []);

  function formatDateTime(date) {
    const datetime = new Date(date);
    const day = String(datetime.getDate()).padStart(2, "0");
    const month = String(datetime.getMonth() + 1).padStart(2, "0");
    const year = datetime.getFullYear();
    const hours = String(datetime.getHours()).padStart(2, "0");
    const minutes = String(datetime.getMinutes()).padStart(2, "0");
    const seconds = String(datetime.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }


  return (
    <>
      <AdminContext.Provider
        value={{
          web3,
          settings: settings,
          getudata,
          copyText,
          formatDateTime,
          formatAddress,
          dashd, token, setToken, pWithdrawal, setPWithdrawal, pwtoken, setPWToken, fromLocation, setfromLocation, getsmcs, tokenrate,
        }}
      >
      <ToastContainer />
        <App />
      </AdminContext.Provider>
    </>
  );
}

export default Admincontext;
