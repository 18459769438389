import React, { useContext, useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';
import Copy from '../Comman/Copy';

export default function PendingWithdrawal() {
  const { formatAddress, toastSuccess, toastError } = useContext(AdminContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [receipt, setReceipt] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [authNumber, setAuthNumber] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Generate a random hash
  const generateRandomHash = () => {
    const length = 20;
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join('');
  };


  const insertCode = async () => {
    try {
      const stateString = localStorage.getItem('g4fs34t3f4s5a');

      if (!stateString) {
        throw new Error("User  state not found in localStorage. Please log in first.");
      }

      let state;
      try {
        state = JSON.parse(stateString);
      } catch (err) {
        state = { token: stateString };
      }

      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "verify",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        authNumber,
        username: state.username || '',
        _id: state._id || '',
        token: state.token || '',
      });

      const { resCode, error, token } = response.data;

      if (resCode === 200 && token) {
        // setMsg("Verified successfully");
        // setShowMsg(true);
        setIsAuthenticated(true);
      } else {
        throw new Error(error || "Verification failed. Please try again.");
      }
    } catch (err) {
      setErrorMessage(err.message || "Network error. Please try again later.");
      setShowError(true);
    }
  };


  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "admin",
          submethod: "pwithdrawl",
          key: process.env.REACT_APP_KEY,
          search: search,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            setData([]);
            return;
          }
          setData(res.data.data);
          setPages(Math.ceil(res.data.total / pageSize));
        });
    } catch (err) {
      console.log("Error fetching data:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [pages, pageSize, currentPage, search]);

  const openWithdrawModal = (entry) => {
    setReceipt(entry); // Set the selected entry as the receipt
    // setTransactionHash(generateRandomHash()); // Generate a random hash for the transaction
    setWithdrawModalOpen(true);
  };


  const closeWithdrawModal = () => {
    setWithdrawModalOpen(false);
    setTransactionHash("");
    setShowError(false);
    setShowMsg(false);
  };

  const insertData = async () => {


    if (!transactionHash) {
      setErrorMessage("Transaction Hash is missing. Please try again.");
      setShowError(true);
      return;
    }

    if (!receipt || !receipt.username || !receipt.amount) {
      setErrorMessage("Invalid receipt data. Please try again.");
      setShowError(true);
      return;
    }
    console.log("receipt.username, receipt.hash", receipt.username, transactionHash);

    try {
      setLoading(true);

      const payload = {
        method: "admin",
        submethod: "givewithdrwal", // Ensure this matches backend requirements
        username: receipt.username, // Map from the receipt object
        hash: transactionHash,
        key: process.env.REACT_APP_KEY,
      };

      console.log('Request Payload:', payload);

      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, payload);

      console.log('Backend Response:', response.data);

      if (response.data?.resCode === 200) {
        setMsg(response.data.msg || "Transaction successfully processed!");
        setShowMsg(true);

        // Update the local table data with the new hash
        setData((prevData) =>
          prevData.map((entry) =>
            entry.username === receipt.username
              ? { ...entry, hash: transactionHash, status: true }
              : entry
          )
        );

        closeWithdrawModal(); // Close the modal
        getData()
      } else {
        setErrorMessage(response.data?.error || "Transaction failed.");
        setShowError(true);
      }
    } catch (error) {
      console.error("Error processing transaction:", error);
      setErrorMessage("Failed to process the transaction. Please try again later.");
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };


  return (
    <main className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header />
      <div className="page-body-wrapper">
        <Sidebar />
        <div className="page-body">
          <div className="container-fluid">
            <div className="row page-title">
              <div className="col-sm-6">
                <h3>Pending Withdrawal</h3>
              </div>
            </div>
          </div>
          {!isAuthenticated ? (
            <div className="auth-form">
              <h3 className="text-center">Authentication</h3>
              <div className="form-group">
                <label className="form-label">Authentication Number</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setAuthNumber(e.target.value)}
                />
              </div>
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {msg && <div className="alert alert-success">{msg}</div>}
              <button className="btn btn-primary btn-block" onClick={insertCode}>
                Log In
              </button>
            </div>
          ) : (
            <>
              <div className="form-head d-flex align-items-center">
                <div className="input-group search-area d-inline-flex m-3" style={{ width: "25%" }}>
                  <input
                    type="text"
                    className="form-control form-control-rev pe-0"
                    placeholder="Search here"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="input-group-text input-group-text-rev "
                      onClick={() => setSearch("")}
                    >
                      {search ? (
                        <img
                          src="./assets/images/close.png"
                          alt=""
                          style={{ height: "30px" }}
                        />
                      ) : (
                        <i className="flaticon-381-search-2" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="table-responsive signal-table p-3">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Address</th>
                              <th>User Name</th>
                              <th>Amount</th>
                              <th>T_Receive</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length ? (
                              data.map((ele, i) => {
                                let ind = currentPage * pageSize - pageSize + (i + 1);
                                return (
                                  <tr key={ele.username}>
                                    <td>{ind}</td>
                                    <td>
                                      <a
                                        target="_blank"
                                        className="text-primary"
                                        href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.address}`}
                                        rel="noreferrer"
                                      >
                                        {formatAddress(ele.address)}
                                      </a>
                                      {ele.address ? <Copy data={ele.address} /> : ""}
                                    </td>

                                    <td>{ele.username || "-"}</td>
                                    <td>
                                      $ {ele && ele.w_amount ? parseFloat(ele.w_amount).toFixed(2) : "0"}
                                    </td>
                                    <td>
                                      $ {ele && ele.amount ? parseFloat(ele.amount).toFixed(2) : "0"}
                                    </td>
                                    <td>{ele.Type || "-"}</td>
                                    <td>
                                      {ele.status ? (
                                        <div className="text-success">Active</div>
                                      ) : (
                                        <div className="text-danger">Not Active</div>
                                      )}
                                    </td>
                                    <td>{ele.date || "-"}</td>
                                    <td>
                                      <button
                                        className="btn btn-success"
                                        onClick={() => openWithdrawModal(ele)}
                                      >
                                        Withdraw
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="8" className="text-center">No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {loading && (
                          <div className="text-center">
                            <p>Loading...</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {withdrawModalOpen && (
                <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Pending Transaction</h5>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={closeWithdrawModal}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label">Hash</label>
                            <input
                              className="form-control"
                              type="text"
                              value={transactionHash}
                              onChange={(e) => setTransactionHash(e.target.value)}
                            />
                          </div>
                          {showError && errorMessage && (
                            <div className="alert alert-danger" role="alert">
                              {errorMessage}
                            </div>
                          )}
                          {showMsg && msg && (
                            <div className="alert alert-success" role="alert">
                              {msg}
                            </div>
                          )}
                          <button
                            type="button"
                            className="btn btn-primary w-100 mt-3"
                            onClick={insertData}
                            disabled={loading}
                          >
                            {loading ? "Processing..." : "Submit"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </main>
  );

}


