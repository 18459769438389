import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';


export default function Sponsor({ username }) {
    const { account, copyaddress, formatAddress, formatDateTime } = useContext(AdminContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "lreward",
                    submethod: "getlreward",
                    key: process.env.REACT_APP_KEY,
                    username: username,
                    search,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    setLoading(false);
                    if (res.data.error) {
                        return "";
                    }
                    console.log('res.data.data111444', res.data.data)
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (username) {
            getData();
        }
    }, [username, pages, pageSize, currentPage]);

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        getData();
    }, [search]);
    return (
        <>
            <div class="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h3>Sponser</h3>
                        </div>
                        <div className="table-responsive signal-table p-3">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>User Name</th>
                                        <th>Sponsor Id</th>
                                        <th>Amount</th>
                                        <th>Level</th>
                                        <th>Reward (%)</th>
                                        <th>Datetime</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data?.length
                                        ? data.map((e, i) => {
                                            let ind = currentPage * pageSize - pageSize + (i + 1);
                                            return (
                                                <tr>
                                                    <td >
                                                        {ind}
                                                    </td>
                                                    <td>
                                                        <a
                                                            target="_blank"
                                                            className="text-primary"
                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${e.username}`}
                                                            rel="noreferrer"
                                                        >
                                                            {typeof e.username === 'string' ? formatAddress(e.username) : e.username}
                                                        </a>
                                                        {e.username ? <Copy data={e.username} /> : ""}
                                                    </td>
                                                    <td >
                                                        {e.referralId}
                                                    </td>
                                                    <td >
                                                        ${e.amount}
                                                    </td>
                                                    <td >
                                                        {e.level}
                                                    </td>
                                                    <td >
                                                    $ {e.reward} ({e.lrewardper}%)
                                                    </td>
                                                    <td >
                                                        <div className='d-flex justify-content-center'>
                                                            {formatDateTime(e.createdAt)}
                                                        </div>
                                                    </td>

                                                </tr>
                                            );
                                        })
                                        : ""}
                                </tbody>
                            </table>
                            {loading ? (
                                <div className="text-center">
                                    <p>Loading...</p>
                                </div>
                            ) : !data || !data.length ? (
                                <div className="text-center">
                                    <p>No data found.</p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className='d-flex justify-content-center py-4'>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                            {/* <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </nav> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
