import React, { useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Sidebar from '../Comman/Sidebar'
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function Dashboard() {
    const [dashboardData, setDashboardData] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [settingData, setSettingData] = useState(null);
    const [ownerContractBal, setOwnerContractBal] = useState(0);
    const getDashboardData = async () => {
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "admin",
                    submethod: "gettotaldata",
                    key: process.env.REACT_APP_KEY,
                })
                .then((res) => {
                    if (res.data.error) {
                        return "";
                    }
                    console.log("user dashdata 123 12142154", res.data);
                    // console.log("user dashdata 123", res.data.today);
                    setDashboardData(res.data);
                    setDashboard(res.data.today);
                });
        } catch (err) {
            console.log("error : ", err);
        }
    };
    useEffect(() => {
        getDashboardData();
        // getSettingData();
    }, []);
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page sidebar start*/}
                    <Sidebar />
                    {/* Page sidebar end*/}
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>MINNION Dashboard</h3>
                                </div>
                                <div className="col-sm-6">
                                    <div className='d-flex justify-content-end'>
                                        {/* <Link to="/deposit" className="btn btn-primary mx-2">Pending Transcation</Link> */}
                                        <Link to="/UserDeposit" className="btn btn-primary mx-2">User Deposit</Link>
                                        <Link to="/CoinTransfer" className="btn btn-primary mx-2">Coin Transfer</Link>
                                        {/* <button className="btn btn-primary mx-2" disabled>Security Vault</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts*/}
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {/* Order Delivery*/}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Users</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.totalusers : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Active Users</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.activeuser : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">I Phone User</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.iphoneuser : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Level Reward($)</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                $ {dashboardData && dashboardData.lrewards
                                                                    ? parseFloat(dashboardData.lrewards).toFixed(2) // Converts to 2 decimal places
                                                                    : "0.00"}{" "}
                                                                <small>{process.env.REACT_APP_TICKER_USDN}</small>
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Minnion Points</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.point : "0"}
                                                                {" "} <small>{process.env.REACT_APP_TICKER}</small>
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Roi Reward($)</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.roireward : "0"}
                                                            {" "} <small>{process.env.REACT_APP_TICKER}</small>                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Withdrawal($)</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                $ {dashboardData && dashboardData.withdrawlD
                                                                    ? parseFloat(dashboardData.withdrawlD).toFixed(2) // Converts to 2 decimal places
                                                                    : "0.00"}{" "}
                                                                <small>{process.env.REACT_APP_TICKER_USDN}</small>
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                 {dashboardData && dashboardData.withdrawlT
                                                                    ? parseFloat(dashboardData.withdrawlT).toFixed(2) // Converts to 2 decimal places
                                                                    : "0.00"}{" "}
                                                                    <small>{process.env.REACT_APP_TICKER}</small>                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Received Ton</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                 {dashboardData && dashboardData.coinamount
                                                                    ? parseFloat(dashboardData.coinamount).toFixed(2) // Converts to 2 decimal places
                                                                    : "0.00"}{" "}{"TON"}
                                                                                                                              </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Node Pending Withdrawal Coin</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.p_withdrawal_coin : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">New Orders</h5>
                                                                <div className="badge align-items-center d-flex badge-light-success">
                                                                    <svg className="feather me-1 pt-0 stroke-success">
                                                                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-down-right" />
                                                                    </svg><span className="f-w-500">4.5%</span>
                                                                </div>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 51,325</h3>
                                                        </div>
                                                        <div className="flex-shrink-0 bg-light-secondary">
                                                            <svg className="svg-w-30 fill-secondary">
                                                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#order-product" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">
                                                    <div>
                                                        <p className="f-w-500">14% since Last Month</p>
                                                    </div>
                                                    <div className="order-chart">
                                                        <div className="chart" id="order-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Order Delivery</h5>
                                                                <div className="badge align-items-center d-flex badge-light-danger">
                                                                    <svg className="feather me-1 pt-0 stroke-danger">
                                                                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-up-right" />
                                                                    </svg><span className="f-w-500">2.3%</span>
                                                                </div>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 32,587</h3>
                                                        </div>
                                                        <div className="flex-shrink-0 bg-light-tertiary">
                                                            <svg className="svg-w-31 fill-tertiary">
                                                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#delivery-van" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">
                                                    <div>
                                                        <p className="f-w-500">10% since Last Month</p>
                                                    </div>
                                                    <div className="order-chart">
                                                        <div className="chart" id="delivery-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Order Delivery</h5>
                                                                <div className="badge align-items-center d-flex badge-light-danger">
                                                                    <svg className="feather me-1 pt-0 stroke-danger">
                                                                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-up-right" />
                                                                    </svg><span className="f-w-500">2.3%</span>
                                                                </div>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 32,587</h3>
                                                        </div>
                                                        <div className="flex-shrink-0 bg-light-tertiary">
                                                            <svg className="svg-w-31 fill-tertiary">
                                                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#delivery-van" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">
                                                    <div>
                                                        <p className="f-w-500">10% since Last Month</p>
                                                    </div>
                                                    <div className="order-chart">
                                                        <div className="chart" id="delivery-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid ends*/}
                    </div>
                    <Footer />
                </div>

            </main>


        </>
    )
}
